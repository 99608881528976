import * as React from "react";
import Helmet from "react-helmet";

const BlankPage: React.FC = (props) => {
  return (
    <Helmet>
      <meta httpEquiv="refresh" content={`0;URL='/en-us/'`} />
    </Helmet>
  );
};

export default BlankPage;
